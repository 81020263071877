<template>
  <div class="flex flex-col flex-1 h-full overflow-hidden">
    <div>
      <nav class="mr-auto flex flex-wrap justify-between items-center bg-white border-b shadow px-5 py-3">
        <div class="flex justify-start items-center space-x-3">
          <Button @click="$router.push({ name: 'Design', params: { id } })" type="button" :label="$t('templateBuilder.backToDesign')" iconPos="left" icon="pi pi-arrow-left" class="p-button-light shadow" />
          <div v-if="templateId" class="flex flex-center space-x-2">
            <div class="inline-flex items-center rounded-md bg-gray-50 py-1 px-2 text-xs font-medium text-gray-400 ring-1 ring-inset ring-gray-500/10">ID: <span class="pl-1 text-gray-700">{{ templateId }}</span></div>
          </div>
          <div v-if="formTemplate.name" class="template text-lg text-gray-700 font-semibold line-clamp-1">{{ formTemplate.name }}</div>
        </div>
        <div class="flex justify-end items-center space-x-3">
          <div class="p-buttonset">
            <Button class="p-button-light shadow" @click="toggleStructure(!editor.actions.structure)" v-tooltip.bottom="$t('templateBuilder.struct')">
              <Icon v-if="!editor.actions.structure" icon="tabler:border-all" class="w-5 h-5" />
              <Icon v-if="editor.actions.structure" icon="tabler:border-none" class="w-5 h-5" />
            </Button>
            <Button class="p-button-light shadow" @click="togglePreview(!editor.actions.preview)" v-tooltip.bottom="$t('templateBuilder.preview')">
              <Icon v-if="!editor.actions.preview" icon="tabler:eye" class="w-5 h-5" />
              <Icon v-if="editor.actions.preview" icon="tabler:eye-off" class="w-5 h-5" />
            </Button>
          </div>

          <Button @click="downloadTemplate" type="button" icon="pi pi-download" class="p-button-outlined p-button-primary shadow" :disabled="savingTemplate" v-tooltip.bottom="$t('templateBuilder.download')" />
          <Button @click="openSyncModal" type="button" :label="$t('design.export')" :disabled="exportToForm.form.busy" class="p-button-light shadow" />
          <Button @click="validAndSaveTemplate" type="button" :label="$t('templateBuilder.save')" :disabled="savingTemplate" class="p-button-primary shadow" />
        </div>
      </nav>
    </div>
    <div class="min-h-0 flex flex-col flex-1 h-full">
      <div id="editor-plugin-container" class="flex overflow-hidden min-h-full"></div>
    </div>

    <Dialog :header="$t('templateBuilder.saveTemplate')" v-model:visible="dialog.show" position="center" modal :dismissableMask="true" :style="{ width: '30rem', padding: 0 }">
      <div class="flex flex-col">
        <form @submit.prevent="saveTemplate" @keydown="formTemplate.onKeydown($event)">
          <div class="form-group">
            <label for="name" class="form-label">
              {{ $t("templateBuilder.name") }}
              <LabelRequired />
            </label>
            <InputText v-model="formTemplate.name" id="name" name="name" type="text" class="p-inputtext-sm flex flex-1 flex-col" autofocus />
            <HasError :form="formTemplate" field="name" />
          </div>
          <div class="form-group">
            <label for="tags" class="form-label">
              {{ $t("templateBuilder.tags") }}
            </label>
            <AutoComplete v-model="formTemplate.tags" id="tags" name="tags" class="p-inputtext-sm flex flex-1 flex-col w-full" multiple :suggestions="tags" @complete="searchTags" />
            <HasError :form="formTemplate" field="tags" />
          </div>
          <div class="form-group">
            <label for="notes" class="form-label">{{ $t("templateBuilder.notes") }}</label>
            <Textarea v-model="formTemplate.notes" rows="2" id="notes" name="notes" class="p-inputtext-sm" />
            <HasError :form="formTemplate" field="notes" />
          </div>
          <div class="flex items-center justify-end space-x-3 pt-3">
            <Button @click="dialog.show = false" type="button" :label="$t('templateBuilder.cancel')" class="p-button-light shadow" />
            <Button type="submit" :label="$t('templateBuilder.save')" :loading="savingTemplate" class="p-button-primary shadow" />
          </div>
        </form>
      </div>
    </Dialog>

    <Dialog :header="$t('templateBuilder.saveBlock')" v-model:visible="saveRow.show" position="center" modal @hide="saveRowCancel" :style="{ width: '35rem', padding: 0 }" class="p-dialog-spacing-normal p-dialog-footer-with-bg">
      <div class="flex flex-col">
        <div class="form-group">
          <label class="form-label">
            {{ $t("templateBuilder.name") }}
            <LabelRequired />
          </label>
          <InputText v-model="saveRow.form.name" id="name" name="name" type="text" class="p-inputtext-sm" />
        </div>
        <div class="form-group">
          <label class="form-label">
            {{ $t("templateBuilder.category") }}
            <LabelRequired />
          </label>
          <Dropdown v-model="saveRow.form.category" :options="saveRow.config.categoryOptions" :editable="true" optionLabel="name" optionValue="code" dataKey="code" class="w-full p-inputtext-sm shadow-sm" placeholder="Selecciona una categoría o agrega una nueva" />
          <label class="form-label-help mt-2" v-html="$t('templateBuilder.categoryHelpPart1')"></label>
          <label class="form-label-help mt-1" v-html="$t('templateBuilder.categoryHelpPart2')"></label>
        </div>
      </div>
      <template #footer>
        <div class="flex-center justify-end space-x-1">
          <Button @click="saveRowCancel" type="button" :label="$t('templateBuilder.cancel')" class="p-button-light shadow-sm" />
          <Button @click="saveRowSave" type="button" :label="$t('templateBuilder.save')" class="p-button-primary shadow" />
        </div>
      </template>
    </Dialog>

    <Dialog :header="$t('templateBuilder.saveBlock')" v-model:visible="saveRowEdit.show" position="center" modal @hide="saveRowEditCancel" :style="{ width: '35rem', padding: 0 }" class="p-dialog-spacing-normal p-dialog-footer-with-bg">
      <div class="flex flex-col">
        <div class="form-group">
          <label class="form-label">
            {{ $t("templateBuilder.name") }}
            <LabelRequired />
          </label>
          <InputText v-model="saveRowEdit.form.name" id="name" name="name" type="text" class="p-inputtext-sm" />
        </div>
        <div class="form-group">
          <label class="form-label">
            {{ $t("templateBuilder.category") }}
            <LabelRequired />
          </label>
          <Dropdown v-model="saveRowEdit.form.category" :options="saveRow.config.categoryOptions" :editable="true" optionLabel="name" optionValue="code" dataKey="code" class="w-full p-inputtext-sm shadow-sm" placeholder="Selecciona una categoría o agrega una nueva" />
          <label class="form-label-help mt-2" v-html="$t('templateBuilder.categoryHelpPart1')"></label>
          <label class="form-label-help mt-1" v-html="$t('templateBuilder.categoryHelpPart2')"></label>
        </div>
      </div>
      <template #footer>
        <div class="flex-center justify-end space-x-1">
          <Button @click="saveRowEditCancel" type="button" :label="$t('templateBuilder.cancel')" class="p-button-light shadow-sm" />
          <Button @click="saveRowEditSave" type="button" :label="$t('templateBuilder.save')" class="p-button-primary shadow" />
        </div>
      </template>
    </Dialog>

    <Dialog :header="$t('Search products')" v-model:visible="productRecommendation.show" position="center" modal @hide="productRecommendationCancel" :style="{ width: '60rem', padding: 0 }" class="p-dialog-spacing-normal p-dialog-footer-with-bg">
      <div class="w-full">
        <nav aria-label="Progress">
          <ol role="list" class="divide-y divide-gray-300 rounded-md border border-gray-300 md:flex md:divide-y-0 -mt-3">
            <li v-for="(step, stepIdx) in steps" :key="step.step" @click="currentStep = step.step" class="relative md:flex md:flex-1 cursor-pointer group">
              <div class="flex items-center px-3 py-2 text-sm font-medium" aria-current="step">
                <span :class="[currentStep == step.step ? 'flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-blue-500' : 'flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-gray-300 group-hover:border-gray-400']">
                  <span :class="[currentStep == step.step ? 'text-blue-500' : 'text-gray-500 group-hover:text-gray-900']">{{ step.id }}</span>
                </span>
                <span :class="[currentStep == step.step ? 'ml-4 text-sm font-medium text-blue-500' : 'ml-4 text-sm font-medium text-gray-500 group-hover:text-gray-900']">{{ step.name }}</span>
              </div>
              <template v-if="stepIdx !== steps.length - 1">
                <div class="absolute top-0 right-0 hidden h-full w-5 md:block" aria-hidden="true">
                  <svg class="h-full w-full text-gray-300" viewBox="0 0 22 80" fill="none" preserveAspectRatio="none">
                    <path d="M0 -2L20 40L0 82" vector-effect="non-scaling-stroke" stroke="currentcolor" stroke-linejoin="round" />
                  </svg>
                </div>
              </template>
            </li>
          </ol>
        </nav>

        <div class="flex flex-col py-4">
          <div v-if="currentStep == 'design'">
            <div class="form-group">
              <label class="form-label-inline">
                {{ $t("templateBuilder.design") }}
                <LabelRequired />
              </label>
              <label class="form-label-help">{{ $t("Layouts allow you to add 1-4 contents per row.") }}</label>
              <div class="grid grid-cols-4 gap-3">
                <div class="relative">
                  <div class="absolute p-0.5 -right-1 -bottom-1 bg-[#39a8f3] rounded-full" v-if="productRecommendation.form.design.columns == 1">
                    <Icon icon="tabler:check" class="w-4 h-4 text-white" />
                  </div>
                  <div class="grid grid-cols-1 gap-1 border-2 rounded p-1 transition-all duration-100" :class="[productRecommendation.form.design.columns == 1 ? 'border-[#39a8f3]' : 'border-gray-300 hover:border-gray-400 hover:cursor-pointer']" @click="productRecommendation.form.design.columns = 1">
                    <div class="text-gray-400 bg-gray-200 rounded py-5 font-bold text-center">1</div>
                  </div>
                </div>
                <div class="relative">
                  <div class="absolute p-0.5 -right-1 -bottom-1 bg-[#39a8f3] rounded-full" v-if="productRecommendation.form.design.columns == 2">
                    <Icon icon="tabler:check" class="w-4 h-4 text-white" />
                  </div>
                  <div class="grid grid-cols-2 gap-1 border-2 rounded p-1 transition-all duration-100" :class="[productRecommendation.form.design.columns == 2 ? 'border-[#39a8f3]' : 'border-gray-300 hover:border-gray-400 hover:cursor-pointer']" @click="productRecommendation.form.design.columns = 2">
                    <div class="text-gray-400 bg-gray-200 rounded py-5 font-bold text-center">1</div>
                    <div class="text-gray-400 bg-gray-200 rounded py-5 font-bold text-center">2</div>
                  </div>
                </div>
                <div class="relative">
                  <div class="absolute p-0.5 -right-1 -bottom-1 bg-[#39a8f3] rounded-full" v-if="productRecommendation.form.design.columns == 3">
                    <Icon icon="tabler:check" class="w-4 h-4 text-white" />
                  </div>
                  <div class="grid grid-cols-3 gap-1 border-2 rounded p-1 transition-all duration-100" :class="[productRecommendation.form.design.columns == 3 ? 'border-[#39a8f3]' : 'border-gray-300 hover:border-gray-400 hover:cursor-pointer']" @click="productRecommendation.form.design.columns = 3">
                    <div class="text-gray-400 bg-gray-200 rounded py-5 font-bold text-center">1</div>
                    <div class="text-gray-400 bg-gray-200 rounded py-5 font-bold text-center">2</div>
                    <div class="text-gray-400 bg-gray-200 rounded py-5 font-bold text-center">3</div>
                  </div>
                </div>
                <div class="relative">
                  <div class="absolute p-0.5 -right-1 -bottom-1 bg-blue-500 rounded-full" v-if="productRecommendation.form.design.columns == 4">
                    <Icon icon="tabler:check" class="w-4 h-4 text-white" />
                  </div>
                  <div class="grid grid-cols-4 gap-1 border-2 rounded p-1 transition-all duration-100" :class="[productRecommendation.form.design.columns == 4 ? 'border-blue-500' : 'border-gray-300 hover:border-gray-400 hover:cursor-pointer']" @click="productRecommendation.form.design.columns = 4">
                    <div class="text-gray-400 bg-gray-200 rounded py-5 font-bold text-center">1</div>
                    <div class="text-gray-400 bg-gray-200 rounded py-5 font-bold text-center">2</div>
                    <div class="text-gray-400 bg-gray-200 rounded py-5 font-bold text-center">3</div>
                    <div class="text-gray-400 bg-gray-200 rounded py-5 font-bold text-center">4</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="flex-center space-x-3">
                <label class="form-label-inline">
                  {{ $t("templateBuilder.repeatPattern") }}
                </label>
                <InputText v-model.number="productRecommendation.form.design.repeat" id="repeat" name="repeat" type="text" class="p-inputtext-sm p-inputtext-12" />
                <label class="form-label-inline">
                  {{ $t("templateBuilder.times") }}
                </label>
              </div>
            </div>
            <div class="form-group">
              <label class="form-label">
                {{ $t("templateBuilder.template") }}
                <LabelRequired />
              </label>
              <div class="grid grid-cols-1 gap-3">
                <div>
                  <Dropdown v-model="productRecommendation.form.design.template" :options="productRecommendation.templateOptions" :loading="productRecommendation.templateClient.busy" optionLabel="name" optionValue="id" dataKey="id" placeholder="--SELECCIONE--" class="p-inputtext-sm w-full shadow-sm" />
                </div>
                <div v-if="templateSelectedThumbnail">
                  <img :src="templateSelectedThumbnail" class="w-full md:w-1/4 mx-auto border rounded-md" />
                </div>
              </div>
            </div>
          </div>
          <div v-if="currentStep == 'products'">
            <div class="form-group">
              <div class="grid grid-cols-2 md:grid-cols-4 gap-3 mb-4">
                <div>
                  <label class="form-label">{{ $t("templateBuilder.source") }}</label>
                  <Dropdown v-model="productRecommendation.productSource" :options="productRecommendation.productSourceOptions" optionLabel="label" optionValue="value" dataKey="value" placeholder="--SELECCIONE--" class="p-inputtext-sm w-full shadow-sm" />
                </div>
                <div class="md:col-span-3">
                  <label class="form-label">{{ $t("templateBuilder.product") }}</label>
                  <AutoComplete v-model="productRecommendation.productModel" :suggestions="productRecommendation.productModelSuggestions" :disabled="!missingProducts" @complete="searchProduct($event)" @item-select="selectProduct($event)" optionLabel="name" placeholder="Buscar por item_id, name, sku, link, brand_name y category_name" scrollHeight="20vw" class="shadow-sm p-inputtext-sm w-full">
                    <template #option="slotProps">
                      <div class="flex-center">
                        <div class="flex-center space-x-3">
                          <div class="w-20 h-20">
                            <img :alt="slotProps.option.name" :src="slotProps.option.image" class="w-20 h-20 object-contain" />
                          </div>
                          <div class="flex-1">
                            <div class="flex text-sm font-semibold text-gray-800">{{ slotProps.option.name }}</div>
                            <div class="flex-center space-x-3">
                              <div class="text-sm font-normal text-gray-400">
                                {{ $t("templateBuilder.source") }}: <span class="text-gray-700 font-medium">{{ slotProps.option.source }}</span>
                              </div>
                              <div class="text-sm font-normal text-gray-400">
                                {{ $t("templateBuilder.lastUpdate") }}: <span class="text-gray-700 font-medium">{{ $moment(slotProps.option.timestamp).fromNow() }}</span>
                              </div>
                              <div class="text-sm font-normal text-gray-400">
                                {{ $t("templateBuilder.brand") }}: <span class="text-gray-700 font-medium">{{ slotProps.option.brand_name }}</span>
                              </div>
                              <div class="text-sm font-normal text-gray-400">
                                {{ $t("templateBuilder.sku") }}: <span class="text-gray-700 font-medium">{{ slotProps.option.sku }}</span>
                              </div>
                              <div class="text-sm font-normal text-gray-400">
                                {{ $t("templateBuilder.id") }}: <span class="text-gray-700 font-medium">{{ slotProps.option.item_id }}</span>
                              </div>
                            </div>
                            <div class="flex-center space-x-3">
                              <div class="text-sm font-normal text-gray-400">
                                {{ $t("templateBuilder.price") }}: <span class="text-gray-700 font-medium">{{ slotProps.option.price }}</span>
                              </div>
                              <div class="text-sm font-normal text-gray-400">
                                {{ $t("templateBuilder.priceDiscount") }}:
                                <span class="text-gray-700 font-medium">{{ slotProps.option.price_sale }}</span>
                              </div>
                            </div>
                            <div class="flex-center space-x-3">
                              <div class="text-sm font-normal text-gray-400">
                                {{ $t("templateBuilder.link") }}: <span class="text-gray-700 font-medium">{{ slotProps.option.link }}</span>
                              </div>
                            </div>
                            <div class="flex-center space-x-3">
                              <div class="text-sm font-normal text-gray-400">
                                {{ $t("templateBuilder.category") }}
                              </div>
                              <Tag v-for="category of slotProps.option.categories" :key="category.id" class="bg-gray-100 text-gray-700 font-normal" :value="`#${category.category_id} - ${category.category_name}`" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </template>
                  </AutoComplete>
                </div>
              </div>

              <Message severity="info" :closable="false" icon="pi pi-info-circle">
                <span v-if="missingProducts">{{ $t("templateBuilder.select") }} {{ missingProducts }} {{ $t("templateBuilder.product") }}(s)</span><span v-else>{{ $t("templateBuilder.selectedAllProducts") }}</span>
              </Message>

              <div class="grid grid-cols-1 gap-3 my-4">
                <div v-if="productRecommendation.productSelected.length" class="space-y-2">
                  <div v-for="(productSelected, productSelectedIndex) in productRecommendation.productSelected" :key="productSelectedIndex">
                    <div class="w-full bg-white rounded border border-gray-300 p-2 shadow-sm relative">
                      <div class="flex-center space-x-3">
                        <div class="w-20 h-20">
                          <img :alt="productSelected.name" :src="productSelected.image" class="w-20 h-20 object-contain" />
                        </div>
                        <div class="flex-1">
                          <div class="flex text-sm font-semibold text-gray-800">{{ productSelected.name }}</div>
                          <div class="flex-center space-x-3 mb-1">
                            <div v-if="productSelected.source" class="text-sm font-normal text-gray-400">
                              {{ $t("templateBuilder.source") }}: <span class="text-gray-700 font-medium">{{ productSelected.source }}</span>
                            </div>
                            <div v-if="productSelected.timestamp" class="text-sm font-normal text-gray-400">
                              {{ $t("templateBuilder.lastUpdate") }}: <span class="text-gray-700 font-medium">{{ $moment(productSelected.timestamp).fromNow() }}</span>
                            </div>
                            <div v-if="productSelected.brand_name" class="text-sm font-normal text-gray-400">
                              {{ $t("templateBuilder.brand") }}: <span class="text-gray-700 font-medium">{{ productSelected.brand_name }}</span>
                            </div>
                            <div v-if="productSelected.sku" class="text-sm font-normal text-gray-400">
                              {{ $t("templateBuilder.sku") }}: <span class="text-gray-700 font-medium">{{ productSelected.sku }}</span>
                            </div>
                            <div v-if="productSelected.item_id" class="text-sm font-normal text-gray-400">
                              {{ $t("templateBuilder.id") }}: <span class="text-gray-700 font-medium">{{ productSelected.item_id }}</span>
                            </div>
                          </div>
                          <div v-if="productSelected.price || productSelected.price_sale" class="flex-center space-x-3 mb-1">
                            <div v-if="productSelected.price" class="text-sm font-normal text-gray-400">
                              {{ $t("templateBuilder.price") }}: <span class="text-gray-700 font-medium">${{ productSelected.price }}</span>
                            </div>
                            <div v-if="productSelected.price_sale" class="text-sm font-normal text-gray-400">
                              {{ $t("templateBuilder.priceDiscount") }}:
                              <span class="text-gray-700 font-medium">${{ productSelected.price_sale }}</span>
                            </div>
                          </div>
                          <div v-if="productSelected.categories.length" class="flex-center space-x-3 mb-1">
                            <div class="text-sm font-normal text-gray-400">
                              {{ $t("templateBuilder.category") }}
                            </div>
                            <Tag v-for="category of productSelected.categories" :key="category.id" class="bg-gray-100 text-gray-700 font-normal" :value="`#${category.category_id} - ${category.category_name}`" />
                          </div>
                        </div>
                        <div class="p-2">
                          <div class="flex-center space-x-1">
                            <Button @click="goToLink(productSelected.link)" icon="pi pi-external-link" class="p-button-light p-button-sm shadow-sm" />
                            <Button @click="moveProduct(productSelectedIndex, productSelectedIndex - 1)" :disabled="productSelectedIndex == 0" type="button" icon="pi pi-arrow-up" class="p-button-light p-button-sm shadow-sm" />
                            <Button @click="moveProduct(productSelectedIndex, productSelectedIndex + 1)" :disabled="productSelectedIndex == productRecommendation.productSelected.length - 1" type="button" icon="pi pi-arrow-down" class="p-button-light p-button-sm shadow-sm" />
                            <Button @click="unselectProduct(productSelectedIndex)" type="button" icon="pi pi-trash" class="p-button-danger p-button-sm shadow" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-else>
                  <div class="relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center">
                    <Icon icon="tabler:polaroid-filled" class="w-6 h-6 mx-auto text-gray-400" />
                    <span class="mt-2 block text-sm font-semibold text-gray-400">Search a product</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <template #footer>
        <div class="w-full">
          <div class="flex-center justify-between">
            <div>
              <Button :disabled="currentStep == 'design'" @click="currentStep = 'design'" type="button" label="Volver" class="p-button-light shadow-sm" />
            </div>
            <div class="flex-center space-x-2">
              <Button v-if="currentStep == 'design'" @click="currentStep = 'products'" type="button" label="Siguiente" class="p-button-light shadow-sm" />
              <Button v-if="currentStep == 'products'" @click="productRecommendationSave" type="button" label="Agregar productos" class="p-button-primary shadow" />
            </div>
          </div>
        </div>
      </template>
    </Dialog>

    <Dialog :header="$t('design.export')" v-model:visible="exportToForm.show" position="center" modal :dismissableMask="true" :style="{ width: '30rem', padding: 0 }">
      <div class="flex flex-col">
        <form @submit.prevent="syncTemplate">
          <div class="form-group">
            <label for="credential" class="form-label">{{ $t("Credencial") }} <LabelRequired /></label>
            <Dropdown v-model="exportToForm.form.project_credential_id" :options="exportToForm.projectCredentials" optionLabel="name" optionValue="id" dataKey="id" placeholder="--SELECCIONE--" class="p-inputtext-sm w-full shadow-sm" />
          </div>
          <div class="flex items-center justify-end space-x-3 pt-3">
            <Button @click="exportToForm.show = false" type="button" :label="$t('cancel')" class="p-button-light shadow" />
            <Button type="submit" :label="$t('design.export')" :loading="exportToForm.form.busy" class="p-button-primary shadow" />
          </div>
        </form>
      </div>
    </Dialog>
  </div>
</template>

<script>
import { Icon } from "@iconify/vue"
import Button from "primevue/button"
import Dropdown from "primevue/dropdown"
import Dialog from "primevue/dialog"
import InputText from "primevue/inputtext"
import Textarea from "primevue/textarea"
import AutoComplete from "primevue/autocomplete"
import Tag from "primevue/tag"
import Message from "primevue/message"

import ApiRoute from "@/config/ApiRoute"
import FormClient from "@/config/FormClient"
import { HasError } from "vform/src/components/tailwind"
import LabelRequired from "@/components/LabelRequired"

import { saveAs } from "file-saver"
import slugify from "slugify"

import WidgetEditorConfig from "@/config/WidgetEditorConfig"

const sprintf = require("sprintf-js").sprintf

export default {
  components: {
    Icon,
    Button,
    Dropdown,
    Dialog,
    InputText,
    Textarea,
    HasError,
    AutoComplete,
    Tag,
    Message,
    LabelRequired
  },

  props: {
    id: String,
    templateId: {
      type: String,
      required: false
    }
  },

  data() {
    return {
      savingTemplate: false,
      tags: [],
      saveRow: {
        show: false,
        resolve: null,
        reject: null,
        args: null,
        form: new FormClient({
          template_id: null,
          project_id: this.id,
          type: "emailSaveRow",
          name: "",
          category: "",
          notes: "",
          tags: [],
          structure: "",
          message: ""
        }),
        config: {
          categoryOptions: [
            { name: "Header", code: "header" },
            { name: "Content", code: "content" },
            { name: "Footer", code: "footer" },
            { name: "Products", code: "products" },
            { name: "Others", code: "others" }
          ]
        }
      },
      saveRowEdit: {
        show: false,
        resolve: null,
        reject: null,
        args: null,
        form: new FormClient({
          template_id: null,
          project_id: this.id,
          type: "emailSaveRow",
          name: "",
          category: "",
          notes: "",
          tags: []
        })
      },
      currentStep: "design",
      steps: [
        { id: "1", name: "Design", step: "design" },
        { id: "2", name: "Products", step: "products" }
      ],
      productRecommendation: {
        show: false,
        resolve: null,
        reject: null,
        form: {
          design: {
            template: null,
            columns: 2,
            repeat: 1
          },
          data: {
            image: true,
            name: true,
            sku: false,
            brand: false,
            description: false,
            price: true,
            price_sale: true,
            installments: true,
            button: true,
            percentage_discount: true,
            discount: false
          },
          extra: {
            price: false,
            button: false,
            percentage_discount: false,
            discount: false,
            installments_number: false,
            installments_price: false,
            price_currency: "",
            price_decimals: "",
            price_decimal_separator: "",
            price_thousands_separator: "",
            button_text: "",
            percentage_discount_position: "right",
            percentage_discount_text: "",
            discount_position: "left",
            discount_text: "",
            installments_number_position: "left",
            installments_number_text: "",
            installments_price_position: "right",
            installments_price_text: ""
          }
        },
        templateOptions: [],
        templateClient: new FormClient({}),
        productSelected: [],
        productOptions: [],
        productClient: new FormClient({
          q: ""
        }),
        productSource: null,
        productSourceOptions: [],
        productModel: null,
        productModelSuggestions: []
      },
      dialog: {
        show: false
      },
      formTemplate: new FormClient({
        template_id: this.templateId || null,
        project_id: this.id,
        type: "email",
        name: "",
        category: "",
        notes: "",
        tags: [],
        structure: "",
        message: ""
      }),
      editor: {
        instance: null,
        token: null,
        template: {},
        client: {
          http: new FormClient({
            client_id: "03db946c-ec90-40b1-a842-f3b6deecbb82",
            client_secret: "Q9muQjU17hA27ITMdhPJFBYfc9heHbgVSbO80PQraKYbD81yA3JQ",
            grant_type: "password"
          }),
          endpoint: "https://auth.getbee.io/apiauth"
        },
        actions: {
          saving: false,
          structure: false,
          preview: false,
          advancedPreview: false
        },
        config: {
          uid: `workspace-${this.id}`,
          container: "editor-plugin-container",
          language: "es-ES",
          loadingSpinnerTheme: "light",
          sidebarPosition: "right",
          defaultModulesOrder: ["Heading", "Paragraph", "Text", "Image", "Button", "Divider", "Spacer", "Social", "Html", "Video", "List", "Menu", "Icons"],
          trackChanges: true,
          saveRows: true,
          preventClose: false,
          specialLinks: [],
          mergeTags: [],
          mergeContents: [],
          contentDefaults: {},
          rowsConfiguration: {
            emptyRows: true,
            defaultRows: true,
            savedRows: true,
            externalContentURLs: []
          },
          contentDialog: {
            saveRow: {
              handler: (resolve, reject, args) => {
                this.saveRow.show = true
                this.saveRow.resolve = resolve
                this.saveRow.reject = reject
                this.saveRow.args = args

                this.saveRow.form.template_id = null
                this.saveRow.form.name = ""
                this.saveRow.form.category = ""
                this.saveRow.form.notes = ""
                this.saveRow.form.tags = []
                this.saveRow.form.structure = ""
                this.saveRow.form.message = ""
              }
            },
            onDeleteRow: {
              handler: async (resolve, reject, args) => {
                try {
                  await new FormClient().delete(sprintf(ApiRoute.template.delete, { template_id: args.row.metadata.id }))
                  resolve(true)
                  this.refreshUI()
                } catch (error) {
                  reject()
                }
              }
            },
            onEditRow: {
              handler: (resolve, reject, args) => {
                this.saveRowEdit.show = true
                this.saveRowEdit.resolve = resolve
                this.saveRowEdit.reject = reject
                this.saveRowEdit.args = args
                this.saveRowEdit.form.template_id = args.row.metadata.id
                this.saveRowEdit.form.name = args.row.metadata.name
                this.saveRowEdit.form.category = args.row.metadata.category
                this.saveRowEdit.form.notes = ""
                this.saveRowEdit.form.tags = []
              }
            },
            externalContentURLs: {
              label: this.$t("Search products"),
              handler: (resolve, reject) => {
                this.currentStep = "design"
                // this.productRecommendation.form = {
                //   design: {
                //     columns: 2,
                //     repeat: 1
                //   },
                //   data: {
                //     image: true,
                //     name: true,
                //     sku: false,
                //     brand: false,
                //     description: false,
                //     price: true,
                //     price_sale: true,
                //     installments: false,
                //     button: true
                //   }
                // }
                // this.productRecommendation.productSelected = []
                // this.productRecommendation.productOptions = []
                // this.productRecommendation.productClient.q = ""
                // this.productRecommendation.productModel = null
                // this.productRecommendation.productModelSuggestions = []

                this.getProductTemplates()
                this.getProductSources()

                this.productRecommendation.show = true
                this.productRecommendation.resolve = resolve
                this.productRecommendation.reject = reject
              }
            }
          },

          onSaveRow: async (rowJSON, htmlFile, jsonFile) => {
            this.saveRow.form.structure = { rowJSON: JSON.parse(rowJSON), pageJSON: JSON.parse(jsonFile) }
            this.saveRow.form.message = htmlFile
            await this.saveRow.form.post(ApiRoute.template.store)
            this.refreshUI()
          },

          onSave: async (jsonFile, htmlFile) => {
            this.formTemplate.structure = JSON.parse(jsonFile)
            this.formTemplate.message = htmlFile

            let templateResponse = await this.formTemplate.post(sprintf(ApiRoute.template.store, { project_id:  this.id}))

            this.formTemplate.template_id = templateResponse.data.data.id

            this.savingTemplate = false
            this.dialog.show = false

            this.$toast.success(this.$t("Template guardado con éxito"))
          },

          onSend: (htmlFile) => {
            let filename = new Date().getTime()
            if (this.templateId) {
              filename = this.formTemplate.name
            }

            saveAs(new Blob([htmlFile]), `${filename}.html`)
            this.savingTemplate = false
          },

          onError: (errorMessage) => {
            console.log("onError ", errorMessage)
          }
        }
      },
      exportToForm: {
        show: false,
        template: {},
        form: new FormClient({ project_credential_id: null }),
        projectCredentials: [],
        projectCredentialsClient: new FormClient()
      }
    }
  },

  computed: {
    showAdditionalSettings() {
      return this.productRecommendation.form.data.price || this.productRecommendation.form.data.price_sale || this.productRecommendation.form.data.button || this.productRecommendation.form.data.percentage_discount || this.productRecommendation.form.data.discount
    },

    missingProducts() {
      return this.productRecommendation.form.design.columns * this.productRecommendation.form.design.repeat - this.productRecommendation.productSelected.length
    },

    templateSelectedThumbnail() {
      let templateIndex = this.productRecommendation.templateOptions.findIndex(pd => pd.id == this.productRecommendation.form.design.template)

      if (templateIndex == -1) {
        return ""
      }

      return this.productRecommendation.templateOptions[templateIndex].thumbnail + '?r=' + Date.now()
    }
  },

  mounted() {
    this.loadUI()
    this.productRecommendation.productSource = this.$store.state.Projects.activeProject?.data_json?.design_source
  },

  methods: {
    async searchTags(event) {
      let response = await new FormClient().get(sprintf(ApiRoute.tags.index) + "?keyword=" + event.query + "&project=" + this.id)
      this.tags = response.data.data.map((tag) => tag.name)
      this.tags.push(event.query)
    },

    async loadUI() {
      let template = {}
      if (this.templateId) {
        // IF WE ARE EDITING THE TEMPLATE THEM WE NEED TO SEARCH IT
        const response = await new FormClient().get(
          sprintf(ApiRoute.template.show, {
            template_id: this.templateId,
            project_id: this.id
          })
        )
        const responseTemplate = response.data.data[0]

        this.formTemplate.name = responseTemplate.name
        this.formTemplate.message = responseTemplate.message
        this.formTemplate.structure = responseTemplate.structure
        this.formTemplate.tags = responseTemplate.tags.map((tag) => tag.name)
        this.formTemplate.notes = responseTemplate.notes
        template = responseTemplate.structure
      } else {
        // SET BLANK TEMPLATE
       template = {
          "page": {
            "body": {
              "container": {
                "style": {
                  "background-color": "#FFFFFF"
                }
              },
              "content": {
                "computedStyle": {
                  "linkColor": "#0068A5",
                  "messageBackgroundColor": "transparent",
                  "messageWidth": "500px"
                },
                "style": {
                  "color": "#000000",
                  "font-family": "Arial, 'Helvetica Neue', Helvetica, sans-serif"
                }
              },
              "type": "app-app-page-properties",
              "webFonts": []
            },
            "description": "Template for app - Empty",
            "rows": [
              {
                "columns": [
                  {
                    "grid-columns": 12,
                    "modules": [],
                    "style": {
                      "background-color": "transparent",
                      "border-bottom": "0px dotted transparent",
                      "border-left": "0px dotted transparent",
                      "border-right": "0px dotted transparent",
                      "border-top": "0px dotted transparent",
                      "padding-bottom": "5px",
                      "padding-left": "0px",
                      "padding-right": "0px",
                      "padding-top": "5px"
                    },
                    "uuid": "58e9b4f6-a94c-4c06-8402-b90e5e5045dc"
                  }
                ],
                "container": {
                  "style": {
                    "background-color": "transparent",
                    "background-image": "none",
                    "background-position": "top left",
                    "background-repeat": "no-repeat"
                  }
                },
                "content": {
                  "computedStyle": {
                    "hideContentOnDesktop": false,
                    "hideContentOnMobile": false,
                    "rowColStackOnMobile": true,
                    "rowReverseColStackOnMobile": false,
                    "verticalAlign": "top"
                  },
                  "style": {
                    "background-color": "transparent",
                    "background-image": "none",
                    "background-position": "top left",
                    "background-repeat": "no-repeat",
                    "color": "#000000",
                    "width": "500px"
                  }
                },
                "empty": false,
                "locked": false,
                "synced": false,
                "type": "one-column-empty",
                "uuid": "ecb98f28-65e1-4099-804f-2ca1a8b2f9ea"
              }
            ],
            "template": {
              "name": "template-base",
              "type": "basic",
              "version": "2.0.0"
            },
            "title": ""
          }
        }
      }

      try {
        // GET APPEARANCE FOR THE PROJECT
        let appearanceResponse = await new FormClient().get(sprintf(ApiRoute.project.appearance, { project_id: this.id }))
        let appearance = Object.keys(appearanceResponse.data).length ? { ...appearanceResponse.data } : { ...WidgetEditorConfig.form }
        let contentDefaults = {}

        contentDefaults.general = {
          backgroundColor: "#ffffff",
          contentAreaBackgroundColor: "#ffffff",
          defaultFont: appearance.text.styles.fontFamily,
          linkColor: appearance.text.styles.linkColor
        }

        contentDefaults.paragraph = {
          styles: this.adaptAppareanceForEditor(appearance.text.styles),
          blockOptions: this.adaptAppareanceForEditor(appearance.text.styles),
          mobileStyles: this.adaptAppareanceForEditor(appearance.text.mobileStyles)
        }

        contentDefaults.text = {
          html: "Escribe algo increíble como por ejemplo...",
          styles: this.adaptAppareanceForEditor(appearance.text.styles),
          blockOptions: this.adaptAppareanceForEditor(appearance.text.styles),
          mobileStyles: this.adaptAppareanceForEditor(appearance.text.mobileStyles)
        }

        contentDefaults.button = {
          label: appearance.button.label,
          styles: this.adaptAppareanceForEditor(appearance.button.styles),
          mobileStyles: this.adaptAppareanceForEditor(appearance.button.mobileStyles)
        }

        this.editor.config.contentDefaults = contentDefaults
      } catch (error) {
        console.log(error)
      }

      // GET FIELDS FOR MAILUP
      let fields = await this.getFields()
      this.editor.config.mergeTags = fields

      // GET SAVE ROWS
      this.editor.config.rowsConfiguration.externalContentURLs = await this.getGroupedCategories()

      // GET TOKEN FOR THE EDITOR PLUGIN
      const token = (await this.editor.client.http.post(this.editor.client.endpoint)).data

      // INIT THE EDITOR PLUGIN
      window.BeePlugin.create(token, this.editor.config, (pluginInstance) => {
        this.editor.instance = pluginInstance
        this.editor.instance.start(template)
      })
    },

    async refreshUI() {
      let newConfig = {
        rowsConfiguration: {
          externalContentURLs: await this.getGroupedCategories()
        }
      }

      this.editor.instance.loadConfig(newConfig)
    },

    adaptAppareanceForEditor(dataObject) {
      let newDataObject = { ...dataObject }

      Object.keys({ ...newDataObject }).forEach((dataObjectKey) => {
        let dataObjectValue = newDataObject[dataObjectKey]
        if (["fontSize", "border", "borderRadius", "paddingTop", "paddingRight", "paddingBottom", "paddingLeft", "marginTop", "marginRight", "marginBottom", "marginLeft"].indexOf(dataObjectKey) > -1) {
          dataObjectValue = `${dataObjectValue}px`
        }

        if (dataObjectKey == "border") {
          newDataObject["borderTop"] = `${dataObjectValue} ${newDataObject["borderStyle"]} ${newDataObject["borderColor"]}`
          newDataObject["borderRight"] = `${dataObjectValue} ${newDataObject["borderStyle"]} ${newDataObject["borderColor"]}`
          newDataObject["borderBottom"] = `${dataObjectValue} ${newDataObject["borderStyle"]} ${newDataObject["borderColor"]}`
          newDataObject["borderLeft"] = `${dataObjectValue} ${newDataObject["borderStyle"]} ${newDataObject["borderColor"]}`
        }

        newDataObject[dataObjectKey] = dataObjectValue
      })

      return newDataObject
    },

    async getGroupedCategories() {
      // GET SAVE ROWS
      let groupedCategories = []

      try {
        this.saveRow.config.categoryOptions.map((category) => {
          groupedCategories.push({
            name: category.name,
            value: `${process.env.VUE_APP_URL_API}/api/2.0/projects/${this.id}/templates/content-saved-rows?type=emailSaveRow&category=${category.code}`,
            handle: category.name,
            behaviors: {
              canEdit: true,
              canDelete: true
            }
          })
        })

        const groupedCategoriesResponse = await new FormClient().get(sprintf(ApiRoute.template.groupedCategories, { project_id: this.id }))
        groupedCategoriesResponse.data.data.map((groupedCategory) => {
          if (groupedCategory.type == "emailSaveRow") {
            if (!this.saveRow.config.categoryOptions.find((category) => category.code == groupedCategory.category)) {
              groupedCategories.push({
                name: groupedCategory.category,
                value: `${process.env.VUE_APP_URL_API}/api/2.0/projects/${this.id}/templates/content-saved-rows?type=emailSaveRow&category=${groupedCategory.category}`,
                handle: groupedCategory.category,
                behaviors: {
                  canEdit: true,
                  canDelete: true
                }
              })

              this.saveRow.config.categoryOptions.push({
                name: groupedCategory.category,
                code: groupedCategory.category
              })
            }
          }
        })
      } catch (error) {
        console.log(error)
      }

      return groupedCategories
    },

    async getFields() {
      let fields = []

      try {
        const credentialsForMailUpChannelResponse = await new FormClient().get(sprintf(ApiRoute.project.credentials, { project_id: this.id }) + "?filter[channel_id][%3D]=2")
        if (credentialsForMailUpChannelResponse.data.data.length) {
          const fieldsResponse = await new FormClient({ config: { ...(credentialsForMailUpChannelResponse.data.data[0]?.config || {}) } }).post(sprintf(ApiRoute.project.fields, { channel_id: 2 }))

          fieldsResponse.data.map((field) => {
            fields.push({
              name: field.label,
              value: "[" + slugify(field.label, "") + "]"
            })
          })
        }
      } catch (error) {
        console.log(error)
      }

      return fields
    },

    toggleStructure(value) {
      this.editor.actions.structure = value
      this.editor.instance.toggleStructure()
    },

    togglePreview(value) {
      this.editor.actions.preview = value
      this.editor.instance.togglePreview()
    },

    toggleAdvancedPreview(value) {
      this.editor.actions.advancedPreview = value
      this.editor.instance.toggleMergeTagsPreview()
    },

    validAndSaveTemplate() {
      this.dialog.show = true
    },

    downloadTemplate() {
      this.savingTemplate = true
      this.editor.instance.send()
    },

    saveTemplate() {
      this.savingTemplate = true
      this.editor.instance.save()
    },

    productRecommendationCancel() {
      this.productRecommendation.reject()
      this.productRecommendation.show = false
    },

    productRecommendationSave() {
      const params = new URLSearchParams({
        columns: this.productRecommendation.form.design.columns,
        repeat: this.productRecommendation.form.design.repeat,
        template: this.productRecommendation.form.design.template,
        itemIds: this.productRecommendation.productSelected.map((product) => product.item_id).join(","),
      })

      this.productRecommendation.resolve({
        name: "Productos seleccionados",
        value: process.env.VUE_APP_URL_API + "/" + sprintf(ApiRoute.template.contentCustomRows, { project_id: this.id }) + `?${params.toString()}`
      })

      this.productRecommendation.show = false
    },

    async searchProduct(event) {
      if (!event.query) {
        this.productRecommendation.productModelSuggestions = []
        return
      }

      if (!this.productRecommendation.productSource) {
        return
      }

      this.productRecommendation.productClient.q = {
        $and: [
          {
            source: this.productRecommendation.productSource
          },
          {
            $or: [
              { link: { $regex: `${event.query}`, $options: "i" } },
              {
                item_id: {
                  $regex: `${event.query}`,
                  $options: "i"
                }
              },
              { name: { $regex: `${event.query}`, $options: "i" } },
              {
                sku: {
                  $regex: `${event.query}`,
                  $options: "i"
                }
              },
              {
                brand_name: {
                  $regex: `${event.query}`,
                  $options: "i"
                }
              },
              { "categories.category_name": { $regex: `${event.query}`, $options: "i" } }
            ]
          }
        ]
      }

      const response = await this.productRecommendation.productClient.get(sprintf(ApiRoute.content.search, { project_id: this.id }))

      this.productRecommendation.productModelSuggestions = response.data.data.map((data) => {
        if (data.categories && Array.isArray(data.categories) && data.categories.length) {
          data.categories = data.categories.filter((category) => {
            return category.category_id ? true : false
          })
          data.categories = data.categories.slice(0, 5)
        } else if (data.categories && data.categories.length) {
          data.categories = [{
            category_id: data.categories,
            category_name: data.categories
          }]
        } else {
          data.categories = []
        }

        return { ...data }
      })
    },

    selectProduct(event) {
      this.productRecommendation.productSelected.push({ ...event.value })
      this.productRecommendation.productModel = null
      this.productRecommendation.productModelSuggestions = []
    },

    unselectProduct(index) {
      this.productRecommendation.productSelected.splice(index, 1)
    },

    moveProduct(from, to) {
      this.productRecommendation.productSelected.splice(to, 0, this.productRecommendation.productSelected.splice(from, 1)[0])
    },

    saveRowCancel() {
      this.saveRow.reject()
      this.saveRow.show = false
    },

    saveRowSave() {
      this.saveRow.resolve({
        name: this.saveRow.form.name,
        category: this.saveRow.form.category
      })
      this.saveRow.show = false
    },

    saveRowEditCancel() {
      this.saveRowEdit.reject()
      this.saveRowEdit.show = false
    },

    async saveRowEditSave() {
      try {
        await this.saveRowEdit.form.post(ApiRoute.template.store)
        this.saveRowEdit.resolve(true)
        this.refreshUI()
      } catch (error) {
        this.saveRowEdit.reject()
      }

      this.saveRowEdit.show = false
    },

    async getProductTemplates() {
      const productTemplatesResponse = await this.productRecommendation.templateClient.get(sprintf(ApiRoute.template.products, { project_id: this.id }))
      this.productRecommendation.templateOptions = productTemplatesResponse.data.data
    },

    async getProductSources() {
      const productSourcesResponse = await this.productRecommendation.templateClient.get(sprintf(ApiRoute.template.groupedSources, { project_id: this.id }))
      this.productRecommendation.productSourceOptions = productSourcesResponse.data.data.map(source => {
        return {
          label: source,
          value: source
        }
      })

      if (this.productRecommendation.productSourceOptions.length && !this.productRecommendation.productSource) {
        this.productRecommendation.productSource = this.productRecommendation.productSourceOptions[0].value
      }
    },

    goToLink(url) {
      window.open(url, '_blank');
    },

    async openSyncModal() {
      this.exportToForm.show = true

      const response = await this.exportToForm.projectCredentialsClient.get(sprintf(ApiRoute.project.credentials, { project_id: this.id }))

      this.exportToForm.projectCredentials = response.data.data.filter((project_credential) => [2, 19].indexOf(project_credential.channel_id) > -1)
    },

    async syncTemplate() {
      try {
        await this.exportToForm.form.post(sprintf(ApiRoute.template.sync, { template_id:  this.formTemplate.template_id }))
        this.$toast.success(this.$t("Template sincronizado con éxito"))
      } catch (error) {
        this.$toast.error(this.$t("Hubo un problema en la sincronización"))
      }
    },
  }
}
</script>
